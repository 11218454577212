@import "./src/scss/variables";

.notes-body {

  &__new-note {
    padding-top: 32px;
  }

  &__header-buttons {
    display: flex;

    .button:first-child {
      margin-right: 24px;
    }
  }

  .reference-number-select {
    padding: 5px;
  }

  .select-search-container {
    margin-left: 2px;
    .css-1s2u09g-control {
      border-radius: 8px;
      height: 40px;
      border: 2px solid var(--customWhite01);
    }
  }
  .date-picker-container {
    width: 350px;
  }
  .comm-count {
    padding: 10px 4px;
    font-size: 14px;
    .info-tooltip {
      svg {
        width: 20px;
        height: 20px;
        margin-right: 3px;
      }
      svg path {
        fill: red;
      }
    }

    &.red-color {
      color: red;
    }
  }

  .new-note {
    &__main {
      display: flex;
      justify-content: space-between;
      padding: 24px 0px;
    }

    &__hold {
      color: $system-red;
    }

    &__comment {
      width: 416px;
      .comment-title {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .data-id-info {
        margin-top: 5px;
        .row-block {
          display: flex;
          flex-direction: row;
          margin: 2px 0px;
          .label {
            width: 40%;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            padding: 4px 0px;
          }
          .value {
            width: 60%;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            padding: 4px 0px;
          }
          .type-container {
            font-size: 10px;
            font-weight: 700;
            border-radius: 4px;
            padding: 4px 8px;
            &.external {
              border: 1px solid #639FF1;
              color: #639FF1;
              background: #EDF4FD;
            }
            &.internal {
              border: 1px solid #231596;
              color: #231596;
              background: #E7E4FF;
            }
          }
          .status-container {
            text-transform: capitalize;
            font-size: 10px;
            font-weight: 700;
            border-radius: 4px;
            padding: 4px 8px;
            border: 1px solid var(--customBlue);
            background: white;
            color: $light-black-02;
            .status-icon {
              display: inline-block;
              width: 8px;
              height: 8px;
              margin-right: 4px;
              border-radius: 8px;
              &.active {
                background-color: var(--customBlue);
              }
              &.delinquent {
                background-color: #DF3B25;
              }
              &.paid_off {
                background-color: #9CA4AF;
              }
              &.established {
                background-color: #12B981;
              }
              &.recalled {
                background-color: #192636;
              }
              &.external {
                background-color: #192636;
              }
              &.on_hold {
                background-color: rgb(179, 179, 14);
              }
            }
          }
        }
      }

      textarea {
        margin-top: 8px;
        padding: 8px 12px;
        min-height: 500px;
        font-size: 14px;
        line-height: 24px;
        border: 1px solid $gray-lines;
        border-radius: 4px;

        &::placeholder {
          color: $gray-medium;
        }
      }
    }

    &__block {
      margin-bottom: 40px;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;

      button {
        margin: 0 8px 8px 0;
      }
    }
  }

  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 462px;
    padding: 20px 24px;
    background-color: #fff;
    box-shadow: 0px 9px 20px rgba(54, 64, 77, 0.14);
    border-radius: 16px;

    label.heading {
      margin-bottom: 8px;
      color: $gray-medium;
    }

    .MuiFormControlLabel-root {
      margin-bottom: 8px;
      font-size: 14px;

      .MuiButtonBase-root {
        padding: 0 10px;
      }

      .MuiRadio-colorPrimary {
        color: var(--customBlue);
      }
    }
  }
}

.custom-select__control--is-focused {
  border-color: var(--customWhite01) !important;
  box-shadow: 0 0 0 1px var(--customBlue) !important;
}

.custom-select__option--is-focused {
  background-color: lightgrey !important;
  color: black !important;
}

.custom-select__option--is-selected {
  background-color: var(--customWhite01) !important;
  color: black !important;
}

.custom-select__option:hover {
  background-color: lightgrey !important;
  color: black !important;
}

