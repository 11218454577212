@import "./src/scss/variables";

.add-plan-modal {
  max-width: 464px;
  border-radius: 8px;

  .modal-body {
    padding: 20px 24px 0;
    margin-bottom: 32px;
  }

  .modal-content {
    border-radius: 16px;
  }

  .close-modal-btn {
    position: absolute;
    right: 30px;
    padding: 0;
    background-color: transparent;
    border: none;
    color: $light-black-01;
  }

  .modal-title {
    color: $light-black-02;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  .add-option-modal__payment-select {
    margin: 18px 0 10px;
    display: flex;
    flex-direction: column;
    padding: 0px 5px;
    .plan-label {
      margin: 0;
      color: $custom-gray;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
    }
    select {
      width: 100%;
      border-radius: 8px;
      height: 40px;
      border: 2px solid $custom-white-01;
      padding: 5px;
    }
    .date-picker-border {
      border: 2px solid var(--customWhite01);
      border-radius: 8px;
      padding-left: 12px;
      .MuiInput-underline:before {
        border: none;
      }
      .MuiFormControl-root {
        width: 100%;
      }
    }
  }
  .litigation-item {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .debt-custom-card {
    width: 416px;
    border: none;
    padding: 0px 5px;
    .debt-custom-card__block {
      border-top: none;

      .form-control {
        width: 100%;
        border-radius: 8px;
        border: 2px solid $custom-white-01;
      }
    }
  }

  .mod-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    padding: 0px 5px;
    button {
      border-radius: 8px;
      font-size: 16px;
    }
    .cancel-btn {
      border: 1px solid $custom-white-01;
      background: #ffffff;
      color: var(--customBlue);
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}
