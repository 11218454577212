@import "./src/scss/variables";

.page-header {
  width: 100%;
  height: 50px;
  background-color: white;
  border-bottom: 1px solid var(--customWhite01);

  &__body {
    // display: flex;
    // justify-content: space-between;
    padding: 0 90px;
    margin: 0 auto;
    height: 100%;

    @media screen and (max-width: 1439px) {
      padding: 0 42px;
    }

    @media screen and (max-width: 767px) {
      padding: 0;
    }

    h2 {
      padding-top: 24px;

      @media screen and (max-width: 767px) {
        padding: 24px;
      }
    }
    .agent-block {
      margin-top: 16px;
      margin-bottom: 26px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .label {
        font-size: 16px;
        font-weight: 400;
        color: #9ca4af;
        &.segment {
          margin-left: 32px;
        }
      }
      .value {
        margin-left: 8px;
        font-size: 16px;
        font-weight: 700;
        color: #9ca4af;
        &.no {
          color: red;
          margin-right: 8px;
        }
        &.yes {
          color: green;
          margin-right: 8px;
        }
      }
    }
  }

  section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &--link {
    position: relative;

    .back-btn {
      position: absolute;
      top: 16px;
      display: flex;
      align-items: center;

      a {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        color: var(--customBlue);
        font-weight: 400;
      }

      svg {
        color: var(--customBlue);
      }
    }

    h2 {
      padding-top: 52px;
      font-size: 32px;
      font-weight: 800;
      color: $light-black-02;
    }
  }

  &--medium {
    height: 120px;
  }

  &--large {
    height: 230px;

    section {
      width: auto;
    }
  }

  &--semi {
    height: 172px;

    @media screen and (max-width: 767px) {
      height: 145px;
    }
  }

  &--with-error {
    @media screen and (max-width: 767px) {
      margin-bottom: 90px;
    }
  }

  &--row {
    section {
      flex-direction: row;
      align-items: center;

      .heading {
        padding-top: 0;
      }
    }
  }

  &__error {
    display: flex;
    justify-content: center;
    padding: 8px;
    background-color: $alert-30;

    .error__container {
      width: 100%;
      max-width: 1440px;
      padding: 0 64px;
      font-size: 14px;
      color: $alert-rich;

      @media screen and (max-width: 767px) {
        padding: 0 8px;
      }

      svg {
        margin-right: 8px;

        path {
          fill: $alert-rich;
        }
      }
    }
  }
}
