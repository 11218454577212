@import "./src/scss/variables";

.payment-plan-container {
  margin-top: 28px;
  background:#FFFFFF;
  border-radius: 16px;
  border: 1px solid var(--customWhite01);
  padding: 32px;
  @media screen and (max-width: 1150px) {
    padding: 20px;
  }
  .title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: $light-black-01;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .asa-status {
      font-size: 12px;
      font-weight: 700;
      border-radius: 4px;
      padding: 0px 8px;
      line-height: 20px;
      height: 20px;
      &.pending {
        background-color: var(--customWhite01);
        color: var(--customBlue);
      }
      &.executed {
        background-color: rgba(0, 102, 68, 0.10);
        color: #006644;
      }
    }
  }
  .body {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1150px) {
      flex-direction: column;
    }
    justify-content: space-between;
    .block-container {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1150px) {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 7px;
        margin-bottom: 7px;
        border-bottom: 1px solid $custom-white-02;
      }
      .label {
        color: $custom-gray;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
      .value {
        color: $light-black-02;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        display: flex;
        .pen-icon {
          margin-left: 10px;
          cursor: pointer;
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        img {
          margin-left: 10px;
          height: 20px;
          cursor: pointer;
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
      }
    }
    .change-payment-method-mobile {
      display: none;
      @media screen and (max-width: 767px) {
        display: block;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color:var(--customBlue);
        background: #FFFFFF;
        box-shadow: $shadow-btn;
        border-radius: 8px;
        border: 1px solid var(--customWhite01);
        padding: 10px;
        margin-top: 20px;
        text-transform: none;
      }
    }
  }
  .footer {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    .see-agreement-btn {
      box-shadow: $shadow-btn;
      border-radius: 8px;
      border: 1px solid var(--customWhite01);
      height: 44px;
      padding: 8px 15px;
      // margin: auto;
      cursor: pointer;
      img {
        width: 18px;
      }
      span {
        margin-left: 11px;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: var(--customBlue);
      }
    }
  }
}
