.app {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  min-height: 100vh;
}

.main {
  flex: 1 1;
  padding-top: 72px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#front-chat-container {
  z-index: 9 !important;
}

.cx-widget-status {
  display: none;
}

#__react-alert__ div div div {
  overflow-wrap: break-word;
  word-break: break-word;
}

#html-tooltip {
  .MuiTooltip-tooltip {
    color: #192636;
    border: 1px solid var(--customWhite01);
    padding: 16px;
    border-radius: 16px;
    width: 300px;
    pointer-events: all;
    .header {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 16px;
        font-weight: 700;
        margin-top: 5px;
      }
      .close-tooltip-btn {
        cursor: pointer;
        padding: 0;
        background-color: transparent;
        border: none;
        color: gray;
      }
    }
    .main-block {
      margin-top: 15px;
      .block-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5px;
        .label {
          color: $custom-gray;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
        .value {
          color: $light-black-02;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          .link-btn {
            color: #639FF1;
          }
        }
      }
    }
  }
}
