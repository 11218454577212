@import "./src/scss/variables";

.delete-plan-modal {
  max-width: 464px;
  border-radius: 8px;

  .modal-body {
    padding: 20px 24px 0;
    margin-bottom: 32px;
  }

  .modal-content {
    border-radius: 16px;
  }

  .close-modal-btn {
    position: absolute;
    right: 30px;
    padding: 0;
    background-color: transparent;
    border: none;
    color: $light-black-01;
  }

  .modal-body {
    padding: 20px 24px 0;
    margin-bottom: 32px;

    .title {
      color: $light-black-02;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    }

    .subtitle {
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }

  .mod-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    padding: 0px 5px;
    button {
      border-radius: 8px;
      font-size: 16px;
    }
    .cancel-btn {
      border: 1px solid $custom-white-01;
      background: #FFFFFF;
      color: var(--customBlue);
    }
    .delete-btn {
      background: $system-red;
    }
  }
}
