@import "./src/scss/variables";

.cancel-docusign-modal {
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .modal-body {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      width: 100vw;
    }
  }
  .mod-header {
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    span {
      font-size: 20px;
      font-weight: bold;
    }
    .close-modal-btn {
      position: absolute;
      right: 30px;
      padding: 0;
      background-color: transparent;
      border: none;
      color: $light-black-01;
    }
  }
  .mod-body {
    .title {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      line-height: 32px;
      max-width: 360px;
      margin: auto;
      padding-top: 30px;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      max-width: 360px;
      margin: auto;
      padding-top: 8px;
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
  .mod-footer {
    margin: 24px;
    display: flex;
    &.center {
      justify-content: center;
    }
    &.space {
      justify-content: space-between;
    }
    .download-receipt-btn {
      box-shadow: $shadow-btn;
      border-radius: 8px;
      border: 1px solid var(--customWhite01);
      width: fit-content;
      height: 44px;
      padding: 8px 15px;
      cursor: pointer;
      img {
        width: 18px;
      }
      span {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: var(--customBlue);
      }
    }
    .sign-btn {
      height: 44px;
      width: 136px;
      justify-content: center;
    }
  }
}
