.modal {
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__close {
    display: flex;
    justify-content: right;
  }

  &__centered-body{
    text-align: center;
    padding: 10px 20px;
  }

  &__body {
    margin-top: 24px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
  }
}
