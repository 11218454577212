@import "./src/scss/variables";

.consumers-navigation {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    overflow-x: auto;
    width: 100vw;

    .nav {
      padding-left: 24px;
      min-width: 380px;
    }
  }

  .nav-tabs {
    border-bottom: none;
    flex-wrap: nowrap;
    overflow-y: hidden;
  }

  .nav-link {
    padding: 8px 12px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $light-black-02;
    white-space: nowrap;
    border-radius: 8px;
    margin-bottom: 20px;

    svg {
      margin-right: 10px;
    }

    &:hover {
      border-color: transparent;
      // border-bottom: 2px solid var(--customBlue);
      cursor: pointer;
    }

    &.active {
      border-color: transparent;
      // border-bottom: 2px solid var(--customBlue);
      color: $black;
      color: var(--customBlue);
      background-color: var(--customWhite01);
      font-weight: 700;
    }

    .MuiButtonBase-root {
      margin-left: 4px;

      &:hover {
        background-color: transparent;
      }
      svg path {
        fill: $alert-rich;
      }
    }
  }

  .accounts-count {
    margin-left: 10px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    color: $gray-medium;
  }

  .profile-info {
    display: flex;
    padding: 8px 12px;
    margin-bottom: 20px;
    &__row {
      display: flex;
      flex-direction: row;
      .label {
        font-size: 16px;
        font-weight: 700;
        color: $custom-gray;
        margin-right: 8px;
        &.ssn {
          margin-left: 8px;
        }
      }
    }
  }
}
