.settings-page {
  .title {
    padding: 0 64px;
    font-size: 32px;
    font-weight: 800;
    margin-top: 32px;
  }
  .tab-container__column {
    min-width: 200px;
  }
}
