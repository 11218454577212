@import "./src/scss/variables";

.docusign-modal {
  max-width: 80%;
  max-height: 90%;
  height: 90%;
  margin: 3rem auto;
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
    max-width: 100%;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .modal-body {
    overflow-y: unset;
    @media screen and (max-width: 767px) { 
      width: 100vw;
    }
    width: 100%;
    padding: 35px;
    .mod-header {
      text-align: left;
      span {
        font-size: 20px;
        font-weight: bold;
      }
      .close-modal-btn {
        position: absolute;
        right: 30px;
        padding: 0;
        background-color: transparent;
        border: none;
        color: $light-black-01;
      }
    }
    iframe {
      height: 100%;
      width: 100%;
      @media screen and (max-width: 767px) {
        width: 320px;
        height: 90%;
      }
    }
    .nacha-con {
      border: 1px solid var(--customWhite01);
      border-radius: 8px;
      padding: 8px;
      margin-top: 12px;
    }
  }
}