.notification-info-container {
  margin-top: 28px;
  margin-bottom: 28px;
  border: 1px solid #df3b25;
  background: rgba(223, 59, 37, 0.1);
  border-radius: 16px;
  padding: 20px 32px;
  display: flex;
  justify-content: center;
  .text-label {
    display: flex;
    font-size: 16px;
    font-weight: 700;
    color: #df3b25;
  }
}
