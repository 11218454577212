@import "./src/scss/variables";

.profile-two-factor-auth {
  margin-top: 30px;
  .two-factor-title {
    font-size: 24px;
    font-weight: 800;
    color: $light-black-01;
  }
  .two-factor-block {
    .toggle-row {
      display: flex;
      flex-direction: row;
      margin-top: 24px;
      .MuiSwitch-root {
        left: -10px;
        .MuiSwitch-switchBase {
          border: 2px solid var(--customBlue);
          color: white;
          padding: 0px;
          margin: 8px;
          .MuiSwitch-thumb {
            width: 18px;
            height: 18px;
          }
        }
        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
          background-color: var(--customBlue);
          opacity: 1;
        }
      }
      .toggle-text {
        font-size: 16px;
        font-weight: 400;
        padding: 7px 0px;
        color: $light-black-02;
      }
    }
  }

}
