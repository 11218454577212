@import "./src/scss/variables";

.customer-service-container {
  margin-top: 28px;
  background:#FFFFFF;
  border-radius: 16px;
  border: 1px solid $custom-white-01;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 32px;
  @media screen and (max-width: 1150px) {
    padding: 20px;
  }
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    .header {
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;
      color: $light-black-01;
      padding-top: 5px;
    }
  }
  .body {
    .header {
      font-size: 16px;
      font-weight: 700;
      color: #313131;
      margin-top: 24px;
    }
    .body-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      .block-container {
        margin-top: 8px;
        flex: 0 0 33%;
        display: flex;
        flex-direction: column;
        .label {
          color: $custom-gray;
          font-size: 16px;
          font-weight: 700;
        }
        .value {
          color: $light-black-02;
          font-size: 16px;
          font-weight: 400;
        }
        
      }
    }
  }
}
