@import "./src/scss/variables";

.contact-preference-modal {
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .modal-body {
    width: 100%;
    padding: 35px;
    @media screen and (max-width: 767px) { 
      width: 100vw;
      padding: 25px;
    }
    .mod-header {
      text-align: left;
      span {
        font-size: 20px;
        font-weight: bold;
      }
      .close-modal-btn {
        position: absolute;
        right: 30px;
        padding: 0;
        background-color: transparent;
        border: none;
        color: $light-black-01;
      }
    }
    .body-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      .time-picker-selector {
        width: 135px;
        border: 2px solid var(--customWhite01);
        border-radius: 8px;
        background: white;
        padding: 1px 10px;
        &.timezone {
          width: 225px;
          @media screen and (max-width: 767px) { 
            width: 100%;
          }
        }
      }
      .MuiSwitch-root {
        left: -10px;
        margin-top: 3px;
        .MuiSwitch-switchBase {
          border: 2px solid var(--customBlue);
          color: white;
          padding: 0px;
          margin: 8px;
          .MuiSwitch-thumb {
            width: 18px;
            height: 18px;
          }
        }
        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
          background-color: var(--customBlue);
          opacity: 1;
        }
      }
      .checkbox-value {
        color: var(--customBlue);
      }
      .timezone-selector {
        display: flex;
        flex-direction: column;
        .title {
          font-weight: 700;
          font-size: 16px;
          padding-top: 7px;
          @media screen and (max-width: 767px) { 
            padding-top: 0px;
            margin-bottom: 10px;
          }
          margin-right: 20px;
        }
      }
      .switcher {
        margin: 10px 0px 0px;
      }
      .esign-btn {
        cursor: pointer;
        margin-top: 10px;
        margin-bottom: 5px;
        border: 1px solid var(--customWhite01);
        border-radius: 8px;
        padding: 4px 10px 4px 10px;
        width: fit-content;
        display: flex;
        flex-direction: row;
        background-color: #e2fdee;
        &.send {
          background-color: #bed3ff;
        }
        .image-container {
          margin-top: 2px;
          margin-right: 5px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #a0cebb;
          &.send{
            background-color: var(--customBlue);
          }
          img {
            width: 12px;
            margin-left: 4px;
            margin-bottom: 5px;
          }
        }
      }
      .days-selector{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        .day-container {
          cursor: pointer;
          color: $light-black-02;
          padding: 8px 10px;
          border: 1px solid var(--customWhite01);
          border-radius: 8px;
          margin-right: 10px;
          @media screen and (max-width: 767px) { 
            margin-right: 6px;
            padding: 7px 7px;
            font-size: 13px;
          }
          &.active {
            background: white;
            color: var(--customBlue);
          }
          svg {
            margin-right: 5px;
          }
        }
      }
      .same-container {
        margin-top: 5px;
      }
      .time-period {
        display: flex;
        flex-direction: column;
        .same-periods {
          display: flex;
          flex-direction: row;
          .to-label {
            padding-top: 8px;
            margin: 0px 8px;
          }
        }
        .days-time-period-container {
          display: flex;
          flex-direction: row;
          margin: 5px 0px;
          .label {
            color: $light-black-02;
            font-size: 16px;
            font-weight: 400;
            padding-top: 8px;
            width: 40px;
          }
          .to-label {
            padding-top: 8px;
            margin: 0px 8px;
          }
        }
      }
      .primary-info-input {
        border: 2px solid var(--customWhite01);
        border-radius: 6px;
        margin-left: 6px;
        padding: 0px 5px;
        width: 180px;
        &.invalid {
          background-color: rgba(255, 0, 0, 0.2);
        }
      }
    }
    .mod-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button {
        border-radius: 8px;
        height: 44px;
        font-size: 16px;
      }
      .cancel-btn {
        border: 1px solid var(--customWhite01);
        background: #FFFFFF;
        color: var(--customBlue);
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      .confirm-btn {
        @media screen and (max-width: 767px) {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}
