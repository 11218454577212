@import "./src/scss/variables";

.plan-selector-container {
  margin-top: 28px;
  background:#FFFFFF;
  border-radius: 16px;
  border: 1px solid $custom-white-01;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 32px;
  @media screen and (max-width: 1150px) {
    padding: 20px;
  }
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    .header {
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;
      color: $light-black-01;
      padding-top: 5px;
    }
    .btn-container {
      display: flex;
      flex-direction: row;
      button {
        font-size: 16px;
      }
      .otp-btn {
        border: 1px solid $custom-white-01;
        background: #FFFFFF;
        color: var(--customBlue);
        border-radius: 8px;
        margin-right: 16px;
      }
    }

  }
  .possible-plan-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: $custom-white-02;
    border: 1px solid $custom-white-01;
    border-radius: 8px;
    margin-top: 12px;
    padding: 16px 20px;
    &.editMode {
      border: 1px solid $custom-Blue;
      background: $custom-white-01;
    }
    &.open-pan {
      background: #E3FCEF;
    }
    .block {
      width: 20%;
      &.short {
        width: 14%;
      }
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1300px) {
        flex-direction: column;
      }
      .label {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: $custom-gray;
        margin-right: 8px;
      }
      .value {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $light-black-02;
      }
    }
    .select-plan-btn {
      background: white;
      border-radius: 8px;
      box-shadow: $shadow-btn;
      border: 1px solid $custom-white-01;
      color: var(--customBlue);
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      text-transform: none;
      padding: 10px;
      width: 115px;
    }
    .delete-plan-btn {
      height: 44px;
      cursor: pointer;
    }
  }
}
