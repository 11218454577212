@import "./src/scss/variables";

.table-wrapper {
  margin-top: 24px;
  border: 2px solid var(--customWhite01);
  border-radius: 8px;
  background: white;
}

.table {
  width: 100%;
  overflow: hidden;
  margin: 0;

  #delete-note-btn {
    padding: 8px;
    height: 32px;
    font-weight: 600;
    margin-top: 5px;
  }

  .role-block {
    display: flex;
    min-width: 100px;

    #unlock-profile-btn {
      position: absolute;
      height: 32px;
      padding: 8px;
      margin-left: 120px;
    }
  }

  thead {
    tr {
      border: none;
    }

    th {
      font-weight: bold;
      font-size: 11px;
      line-height: 16px;
      color: $custom-gray;
      border: none;
      vertical-align: middle;
    }
  }

  td {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: $light-black-02;
    border: none;
  }

  tr {
    border-top: 2px solid var(--customWhite01);
  }

  tr:hover td {
    background-color: var(--customWhite01) !important;
    cursor: pointer;
  }

  .consumer-full-name {
    width: 150px;
    a {
      color: var(--customBlue);
      font-weight: 700;
    }
  }
  .originator {
    width: 140px;
    font-size: 12px;
    line-height: 24px;
  }

  .ssn {
    width: 80px;
  }

  .status {
    width: 130px;
    font-weight: bold;
    font-size: 11px;
    line-height: 24px;
    text-transform: uppercase;
    .status-container {
      background: $custom-white-02;
      border-radius: 4px;
    }
  }

  .status-icon {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 8px;

    &.active {
      background-color: var(--customBlue);
    }

    &.payment {
      background-color: $payment;
    }

    &.delinquent {
      background-color: #DF3B25;
    }

    &.paid-off {
      background-color: $custom-gray;
    }

    &.established {
      background-color: #12B981;
    }

    &.recalled {
      background-color: $light-black-02;
    }
    &.on_hold {
      background-color: rgb(179, 179, 14);
    }
  }

  .tags {
    width: 915px;

    .heading {
      display: inline-block;
      min-width: 125px;
      padding-top: 4px;
      color: $gray-medium;
    }

    &__container {
      display: inline-flex;
      flex-wrap: wrap;

      .other-tag {
        margin: 0 8px 8px 0;
        cursor: pointer;
      }
    }
  }

  .date-time {
    font-size: 12px;
  }

  .author {
    svg {
      margin-right: 16px;
    }
  }

  .details {
    width: 435px;
  }

  .agent-full-name {
    svg {
      margin-right: 16px;
    }
  }

  .created-at {
    width: 190px;
  }
  .author-name {
    width: 200px;
  }
}
