@import "./src/scss/variables";

.full-payment-success-modal {
  @media screen and (max-width: 767px) {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    margin:0;
    min-height: 100px;
  }
  .modal-content {
    border-radius: 16px;
    @media screen and (max-width: 767px) { 
      border-radius: 16px 16px 0px 0px;
    }
  }
  .modal-body {
    border-radius: 16px;
    background-image: url('../../../../scss/gifs/17252-colorful-confetti.gif');
    @media screen and (max-width: 767px) { 
      width: 100vw;
    }
  }
  .mod-header {
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    span {
      font-size: 20px;
      font-weight: bold;
    }
    .close-modal-btn {
      position: absolute;
      right: 30px;
      padding: 0;
      background-color: transparent;
      border: none;
      color: $light-black-01;
    }
  }
  .mod-body {
    .title {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      line-height: 32px;
      max-width: 360px;
      margin: auto;
      padding-top: 30px;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      max-width: 360px;
      margin: auto;
      padding-top: 8px;
    }
    .success-info-container {
      margin-top: 20px;
      padding: 24px;
      .info-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid $custom-white-02;
        padding: 7px 0px;
        .label {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          color: $custom-gray;
        }
        .value {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }
    .mod-footer {
      margin: 24px;
      .download-receipt-btn {
        margin: auto;
        box-shadow: $shadow-btn;
        border-radius: 8px;
        border: 1px solid var(--customWhite01);
        width: fit-content;
        height: 44px;
        padding: 8px 15px;
        // margin: auto;
        cursor: pointer;
        img {
          width: 18px;
        }
        span {
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          color: var(--customBlue);
        }
      }
    }
  }
}
