@import "./src/scss/variables";

.established-plan-info {
  margin-top: 28px;
  background:#FFFFFF;
  border-radius: 16px;
  border: 1px solid $custom-white-01;
  padding: 32px;
  @media screen and (max-width: 1150px) {
    padding: 20px;
  }
  .title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: $light-black-01;
    margin-bottom: 12px;
  }
  .body {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1150px) {
      flex-direction: column;
    }
    justify-content: space-between;
    .block-container {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1150px) {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 7px;
        margin-bottom: 7px;
        border-bottom: 1px solid $custom-white-02;
      }
      .label {
        color: $custom-gray;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
      .value {
        color: $light-black-02;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
    .edit-payment-method {
      display: block;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color:var(--customBlue);
      background: #FFFFFF;
      box-shadow: $shadow-btn;
      border-radius: 8px;
      border: 1px solid $custom-white-01;
      padding: 10px;
      @media screen and (max-width: 1150px) {
        margin-top: 20px;
      }
      text-transform: none;
    }
    .info-icon {
      cursor: pointer;
      margin-top: 10px;
      @media screen and (max-width: 1150px) {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .footer {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    .see-agreement-btn {
      box-shadow: $shadow-btn;
      border-radius: 8px;
      border: 1px solid var(--customWhite01);
      height: 44px;
      padding: 8px 15px;
      // margin: auto;
      cursor: pointer;
      img {
        width: 18px;
      }
      span {
        margin-left: 11px;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: var(--customBlue);
      }
    }
  }
}
