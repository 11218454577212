@import "./src/scss/variables";

.account-details {
  width: 300px;
  height: 420px; 
  overflow-y: scroll;

  .mod-header {
    text-align: left;
    padding: 28px 0 0 28px;

    span {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .mod-body {
    padding: 28px;

    .MuiAccordionSummary-root {
      padding: 0;
    }

    .MuiAccordionSummary-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      .label {
        color: $custom-gray;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        user-select: text;
      }

      .value {
        color: $light-black-02;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        user-select: text;
      }
    }

    .MuiPaper-elevation1 {
      box-shadow: none;
    }

    .MuiAccordionActions-root {
      justify-content: flex-start;
      padding: 0px 10px 10px 10px;
    }
  }
}
