@import "./src/scss/variables";

.consumers {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 116px);
  padding-top: 72px;

  &__footer {
    display: flex;
  }

  .title {
    font-size: 32px;
    font-weight: 800;
    margin-top: 32px;
  }

  .select-search-container {
    margin-right: 4px;
    .css-1s2u09g-control {
      border-radius: 8px;
      height: 40px;
      border: 2px solid var(--customWhite01);
    }
  }
}

.custom-select__control--is-focused {
  border-color: var(--customWhite01) !important;
  box-shadow: 0 0 0 1px var(--customBlue) !important;
}

.custom-select__option--is-focused {
  background-color: lightgrey !important;
  color: black !important;
}

.custom-select__option--is-selected {
  background-color: var(--customWhite01) !important;
  color: black !important;
}

.custom-select__option:hover {
  background-color: lightgrey !important;
  color: black !important;
}
